import { setLocale, Schema, addMethod, array, string } from 'yup'
import locale from '@/plugins/useYupPluginLocale'
import { i18n } from '@/plugins/useVueI18n'
import {
  getFieldFromConfiguration,
  isNotEmpty,
  isSetToBeMandatory,
} from '@/services/useValidation'
import { PhoneNumberRegex, EmailAddressRegex } from '@/utils/regex'

setLocale(locale)

// adds new "mandatoryFromConfig" method
addMethod(Schema, 'mandatoryFromConfig', function (fieldKey, configuration) {
  const { t } = i18n.global
  const errorMessage = () => t('forms.required')

  return this.test(`mandatoryFromConfig`, errorMessage, function (value) {
    const fieldItem = getFieldFromConfiguration(fieldKey, configuration)
    const { path, createError } = this

    if (!isSetToBeMandatory(fieldItem)) {
      return true
    }

    return isNotEmpty(value) || createError({ path, message: errorMessage })
  })
})

// adds new "unique" method for array schema only
addMethod(
  array,
  'unique',
  function (customMessage, mapper = (/** @type {any} */ a) => a) {
    const { t } = i18n.global
    const errorMessage = () => customMessage || t('forms.unique')

    return this.test('unique', errorMessage, function (list) {
      return list.length === new Set(list.map(mapper)).size
    })
  }
)

// overrides built-in "required" method for array schema only
addMethod(array, 'required', function (customMessage) {
  const { t } = i18n.global
  const errorMessage = () => customMessage || t('forms.required')

  return this.test('required', errorMessage, function (list) {
    return !!list?.length
  })
})

// adds new "phone" method for string schema only
addMethod(string, 'phone', function () {
  const { t } = i18n.global
  const errorMessage = () => t('forms.string.phone')

  return this.test('phone', errorMessage, function (value) {
    const { path, createError } = this

    return (
      !isNotEmpty(value) ||
      PhoneNumberRegex.test(value) ||
      createError({ path, message: errorMessage })
    )
  })
})

// overrides built-in "email" method for string schema only
addMethod(string, 'email', function (customMessage) {
  const { t } = i18n.global
  const errorMessage = () => customMessage || t('forms.string.email')

  return this.test('email', errorMessage, function (value) {
    const { path, createError } = this

    const validateLength = (email) => {
      const localPart = String(email).split('@').shift() ?? ''
      const domainPart = String(email).split('@').pop() ?? ''

      const isValid = localPart.length <= 64 && domainPart.length <= 255
      return isValid
    }

    return (
      !isNotEmpty(value) ||
      (EmailAddressRegex.test(value) && validateLength(value)) ||
      createError({ path, message: errorMessage })
    )
  })
})

// overrides built-in "required" method
addMethod(Schema, 'required', function (customMessage) {
  const { t } = i18n.global
  const errorMessage = () => customMessage || t('forms.required')

  return this.test('required', errorMessage, function (value) {
    const { path, createError } = this

    return isNotEmpty(value) || createError({ path, message: errorMessage })
  })
})
