/**
 * Regular expression for validating CSS units.
 *
 * This regex matches:
 * - A number (integer or decimal).
 * - Followed by an optional space.
 * - Followed by a valid CSS unit (cm, mm, in, px, pt, pc, em, ex, ch, rem, vw, vh, vmin, vmax, %).
 * @constant {RegExp}
 */
export const CssUnitRe =
  /(\d*\.?\d+)\s?(cm|mm|in|px|pt|pc|em|ex|ch|rem|vw|vh|vmin|vmax|%)/

/**
 * Regular expression for validating alphanumeric strings.
 *
 * This regex matches:
 * - Strings that contain only alphabetic (a-z, A-Z) and numeric (0-9) characters.
 * @constant {RegExp}
 */
export const AlphaNum = /^[a-z0-9]+$/i

/**
 * Regular expression for validating phone numbers.
 *
 * This regex matches:
 * - An optional plus sign at the beginning.
 * - Followed by one or more numeric characters.
 * @constant {RegExp}
 */
export const PhoneNumberRegex = /^\+?[0-9]+$/

/**
 * Regular expression for validating email addresses.
 *
 * The local part:
 * - Starts with an alphanumeric character.
 * - Ends with an alphanumeric character before the "@" symbol.
 * - Disallows leading, trailing, and consecutive dots.
 * - May include alphanumeric characters, underscores, percent signs, plus signs, or hyphens in the middle.
 *
 * The domain part:
 * - Must start and end with an alphanumeric character.
 * - May contain alphanumeric characters and hyphens in the middle.
 * - Must end with a top-level domain (TLD) that is at least 2 characters long and contains only alphabetic characters.
 * @constant {RegExp}
 */
export const EmailAddressRegex =
  /^[a-zA-Z0-9](?:[a-zA-Z0-9_%+-]*[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9_%+-]*[a-zA-Z0-9])?)*@[a-zA-Z0-9](?:[a-zA-Z0-9.-]*[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/
